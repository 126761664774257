<template>
  <Loading v-if="globalStore.hydrating && !globalStore.hasBeenHydrated" />
  <template v-else>
    <Impersonating />
    <RouterView v-slot="{ Component: RootComponent, route }">
      <component :is="RootComponent" v-bind="route.meta.layoutProps" />
    </RouterView>
  </template>
</template>

<script lang="ts" setup>
import { useGlobalStore } from '@tu/stores';
import Impersonating from '@tu/views/components/Impersonating.vue';
import Loading from '@tu/views/components/Loading.vue';
import { RouterView } from 'vue-router';

const globalStore = useGlobalStore();
</script>
