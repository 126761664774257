<template>
  <div
    v-if="!instanceStore.isProduction && userStore.session.impersonator"
    class="absolute bottom-0 left-0 right-0 z-30 flex items-center justify-center gap-4 bg-serendis-raspberry/80 py-4 text-center text-white"
  >
    You are logged in as {{ userStore.user?.name }}. Be careful!
    <Button :icon="ArrowUturnLeftIcon" preset="secondary" size="sm" @click="switchBack">Return</Button>
  </div>
</template>

<script lang="ts" setup>
import { ArrowUturnLeftIcon } from '@heroicons/vue/24/outline';
import { useInstanceStore, useUserStore } from '@tu/stores';
import Button from './Button.vue';

const userStore = useUserStore();
const instanceStore = useInstanceStore();

const switchBack = async () => {
  await userStore.endImpersonation();
};
</script>
