<template>
  <div class="flex w-full flex-col items-center justify-center gap-10">
    <img
      class="h-8"
      src="/images/sponsorkey_logo.png"
      alt="SponsorKey Logo"
      v-if="appName === 'SponsorKey' || instanceStore.instanceType === 'sponsorkey'"
    />
    <div v-else class="flex items-center gap-2 rounded-lg pr-1 text-white">
      <svg
        viewBox="0 0 226.77 226.77"
        class="w-10"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        overflow="hidden"
      >
        <defs></defs>
        <path
          d="M42.52 0C42.52 0 0 0 0 42.52L0 184.25C0 184.25 0 226.77 42.52 226.77L184.25 226.77C184.25 226.77 226.77 226.77 226.77 184.25L226.77 42.52C226.77 42.52 226.77 0 184.25 0L42.52 0Z"
          fill="#1A3B69"
        />
        <path
          d="M75.53 48.81C45.84 48.81 21.77 72.88 21.77 102.57 21.77 132.26 45.84 156.33 75.53 156.33L129.29 156.33 129.29 102.57C129.29 72.88 105.22 48.81 75.53 48.81"
          fill="#F68621"
        />
        <path
          d="M183.59 177.96C171.77 177.96 162.17 168.37 162.17 156.54L162.17 135.13 183.59 135.13C195.41 135.13 205.01 144.72 205.01 156.54 205.01 168.36 195.42 177.96 183.59 177.96"
          fill="#FFFFFF"
        />
      </svg>
      <img
        class="h-3"
        alt="MentorKey"
        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHwAAAAMCAYAAACgPNl/AAAACXBIWXMAAA7DAAAOwwHHb6hkAAACMUlEQVR4nNSYMU/CUBDHwRURNkw09tXBkcREBSY3Z2NciYsJlMGPYAi0nd3c3Qx+AhN3P4O6mRAdCVCNC7ZEm/J4d++utCRc8gZ697939359ry0ZzxE3GaJ5trhSXR+74mfsiAk01Lm2t6B5dFpsLkgTartGR44fdc0TTIPNqdNtVK1JMOTr+Zp1CvmiOtXAYtW5mp+hf9qwbT5qG7bFLQggBnAqUJ2fA2GhGyWmTgdJp6MA1+Wb8YWF2+INmnzkiGcUwALAVf40gOs0ad0sKhA62NQYybLYXLnD1qaqkQ85i+eYr9rFjACnVoctGHX3fLu7e3F2J9cP1RQHOBVkDOChxn9c9IPf+ar1MJdn/m41hhFfn7QYCQDnLmRgcYDHjUkCeLjbKpclrF5ZxzHVfGAj0vgiH5fIkQ4VBsUPXdFIETj42FrkkTCyxQWUV37+Fqqtd6xWSMe5AaLx6zXrmNzIcoAbPc8VT1x9YFzg/hfJi74eHnD/hbeOzT0HrWZdY/GgLiZwbaOqBU/3SDd6wW9fmE0beFJHOrW+wFRHbLHaOuPouFasNfen+krzDm1EvobFzORIALhqrjSAq3Y56RRjboJ/g17aCkeNA46OY2zg3JjFPstmgVNrCowKXM7JrVVV06AjKhQd9qlUKtdzHB3VVg541I81xgEu98E9nrGT8G8MVLpl/fEStZUEHtjQ3TnHGuMCn+bsinu5vpFjlnU6qB9dn6R/wNrtNciXNPBfAAAA///Rd97iAAAABklEQVQDALVXl4uxV6uGAAAAAElFTkSuQmCC"
      />
    </div>

    <svg
      class="h-16 w-16 animate-spin text-serendis-navy"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
      <path
        class="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useInstanceStore } from '../../stores';

const instanceStore = useInstanceStore();

const appName = computed(() => window.AppConfig?.name);
</script>
