import * as Sentry from '@sentry/vue';
import App from '@tu/App.vue';
import router from '@tu/router';
import '@vuepic/vue-datepicker/dist/main.css';
import { createPinia } from 'pinia';
import { createApp } from 'vue';
import ToastPlugin from 'vue-toast-notification';

import 'vue-toast-notification/dist/theme-bootstrap.css';

const app = createApp(App);
app.use(ToastPlugin, {
  position: 'bottom',
});
app.use(createPinia());

Sentry.init({
  app,
  environment: import.meta.env.VITE_APP_ENV,
  dsn: import.meta.env.VITE_SENTRY_FRONTEND_DSN,
  integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^\//],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

app.use(router);
app.mount('#app');
app.config.globalProperties.window = window;
